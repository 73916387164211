<template>
  <div class="violations-modal-edit">
    <modal-edit-header
      :disabled="!hasChanges"
      :source="initSource"
      @save="save"
      @view="$emit('mode', 'view')"
      @hasChanges="hasChanges = true"
    />

    <r-tabs
      @input="(e) => viewTab = e"
    >
      <r-tab
        v-for="tab in tabs"
        :id="tab.label"
        :key="tab.label"
        :name="tab.title"
        :active="viewTab === tab"
      />
    </r-tabs>

    <div :class="['violations-modal-edit__wrapper', { disabledTab }]">
      <modal-event-tip v-if="initSource.event_id && viewTab !== 'filesComments'" />

      <modal-event-card
        v-if="source.event_id && viewTab === 'main'"
        :source="initSource"
      />

      <attributes-list
        v-if="viewTab === 'main'"
        :model="violationTypeField"
        :source="initSource"
        is-editing
        :related="source.related"
        @hasChanges="hasChanges = true"
      />

      <attributes-list
        v-if="viewTab !== 'filesComments'"
        :model="tabFields"
        :source="initSource"
        :related="initSource.related"
        :mark-items="markItems"
        is-editing
        @hasChanges="hasChanges = true"
      />
      <files-comments
        v-if="viewTab === 'filesComments'"
        :id="activeApplicationId"
        :source_id="source_id"
        column
        container
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { notifyFactory } from '@/utils'
import { fields, sources, tabs, violationTypeField } from '../../../configs'

export default {
  components: {
    modalEditHeader: () => import('./modal-edit-header'),
    modalEventCard: () => import('../modal-event-card'),
    modalEventTip: () => import('../modal-event-tip'),
    attributesList: () => import('@/components/globals/r-modal-elements/attributes-list'),
    filesComments: () => import('@/components/files-comments/files-comments')
  },
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    markItems: {
      type: Array,
      default: () => []
    },
    noViolator: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      initSource: cloneDeep(this.source),
      employeesArrayFields: [
        'violation_appointed_employees',
        'violation_representatives_employees'
      ],
      hasChanges: false,
      viewTab: 'main',
      violationTypeField,
      tabs
    }
  },
  computed: {
    activeApplicationId() {
      return this.source?.id
    },
    tabFields() {
      return fields.filter(f => f.tab === this.viewTab)
    },
    disabledTab() {
      if (!this.noViolator) return false
      if (this.viewTab === 'violator') return true
      return false
    }
  },
  watch: {
    hasChanges(val) {
      if (val) {
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      }
    }
  },
  methods: {
    async save() {
      const { initSource, source_id } = this

      const url = `objectInfo/${source_id}`

      const data = {
        eliminated: initSource?.eliminated
      }
      fields.forEach(f => {
        if (f.read_only) return
        data[f.model] = initSource[f.model]
      })
      data.no = initSource.no
      data.id = initSource.id

      this.employeesArrayFields.forEach(field => {
        const disabled =
            this.source[field]?.map(e => {
              if (e) {
                return {
                  id: e.id,
                  disabled: true
                }
              }
            }) || []
        const newEmployees = data[field]?.map(e => {
          if (e) {
            return {
              employee_id: e.id || e
            }
          }
        }) || []
        data[field] = [...disabled, ...newEmployees]
      })
      data.status_id = 2

      try {
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const message = this.$t('modal-edit:' + 'register_modal::save_text')

        this.$notify(notifyFactory('succcess', message))
      } catch (e) {
        console.warn(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: sources.updateField,
          value: true
        })
        this.$store.commit('CLOSE_MODAL_WINDOW')
      }
    }
  }
}
</script>

<style lang="scss">
.violations-modal-edit {
  display: grid;
  grid-gap: 1.5rem;

  &__wrapper {
    display: grid;
    grid-gap: 1.5rem;

    &.disabledTab {
      position: relative;
      opacity: 0.2;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
